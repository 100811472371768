import { useEffect } from 'react';
import { useHospitalSelectStore } from '@zustandStorage/blogs/hospitalsForSelect';

export default function useLoadHospitals() {
  const { data, loading } = useHospitalSelectStore((state) => state.select);
  const { fetchHospitalForSelect } = useHospitalSelectStore();

  useEffect(() => {
    if (!data.length && !loading) {
      fetchHospitalForSelect({ page: 1, perPage: 5000 });
    }
  }, []);

  return {data, loading};
}