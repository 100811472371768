import * as React from 'react';
import { useEffect } from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';
import { CreateHospitalRequest } from '@api/types';
import { Language } from '@modelTypes/language';
import QuillEditor from '@components/QuillEditor';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { Button, Card, Checkbox, Col, Form, Image, Input, InputNumber, message, Row, Spin, Tooltip } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useHospitalStore } from '@zustandStorage/hospital/hospital';
import isEmpty from 'lodash/isEmpty';
import urlSlug from 'url-slug';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import CustomSelect from '@base/CustomSelect';
import SelectStep from '@containers/Hospitals/components/SelectStep';
import styles from './HospitalInfoStep.module.scss'
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { Hospital } from '@modelTypes/hospital';
import { getSelectValue } from '@helpers/antForm';
import ExtraServicesModal from '@containers/Hospitals/components/ExtraServicesModal/ExtraServicesTrigger';


export default function HospitalInfoStep() {
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [form] = Form.useForm<CreateHospitalRequest>();
  const {
    clearHospitalFormData,
    fetchHospital,
    updateHospital,
    createHospital,
    form: { row, loading }
  } = useHospitalStore()
  const {
    setData,
    fetchCountriesForSelect,
    fetchCertificatesForSelect,
    fetchCitiesForSelect,
    fetchTagsForSelect,
    fetchVendor,
    clearFormData
  } = useSelect();
  const selectCity = useSelect((state) => state.data?.['selectCities']);
  // const selectTags = useSelect((state) => state.data?.['selectTags']);

  const onSelectLanguage = async (language: Language) => {
    setLanguageId(language.id);
    if (id) {
      await fetchHospital(parseInt(id, 10), { languageId: language.id })
        .then((response: Hospital) => {
          resetForm();
          setSelectsInitialDate(response);
        });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnBlur = () => {
    if (isEmpty(form.getFieldValue('urlSlug')) && !isEmpty(form.getFieldValue('title'))) {
      const value = urlSlug(form.getFieldValue('title'));
      form.setFieldValue('urlSlug', value);
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving hospital...',
      duration: 0,
    });

    setFormSubmitting(true);

    const data = form.getFieldsValue(true);
    const formData = {
      ...data,
      languageId,
      countryId: getSelectValue(data.countryId),
      cityId: getSelectValue(data.cityId),
      vendorId: getSelectValue(data.vendorId),
      tagsIds:  getSelectValue(data.tagsIds),
      certificatesIds: getSelectValue(data.certificatesIds),
    };

    if (id) {
      await updateHospital(parseInt(id, 10), formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The hospital has been successfully updated');
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch(() => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
        })
    } else {
      await createHospital(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The hospital has been successfully created');
          history.push(`/hospitals/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch(() => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
        })
    }
  };

  const resetCountryAndCitySelects = () => {
    clearFormData('selectCountries')
    clearFormData('selectCities')
    form.resetFields(['countryId', 'cityId']);
  };

  const setSelectsInitialDate = (response: Hospital) => {
    setData('selectCountries', response && response.country ? [response.country] : []);
    setData('selectCities', response && response.city ? [response.city] : []);
    setData('selectCertificates', response && response.certificates ? response.certificates : []);
    setData('selectTags', response && response.tags ? response.tags : []);
    setData('hospitalSelectVendor', response && response.vendorId ? [response.vendor] : []);
  };

  useEffect(() => {
    return () => {
      clearHospitalFormData();
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.hospitalsAntGrid || {};
          history.push(`/hospitals?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} Hospital`}
        extra={[
          <SelectStep key="selectStep" disabled={!row} />,
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <ExtraServicesModal key="extraModal"/>,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
          id={'test'}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="title"
                label="Title"
                messageVariables={{ name: 'Title' }}
                rules={[{ required: true, type: 'string', max: 255 }]}
                initialValue={row?.title}
              >
                <Input onBlur={handleOnBlur} placeholder="Enter name" />
              </Form.Item>
              <Form.Item
                name="urlSlug"
                label="Url Slug"
                messageVariables={{ name: 'Url Slug' }}
                rules={[{ required: true, type: 'string', max: 128 }]}
                initialValue={row?.urlSlug}
              >
                <Input disabled={languageId > 1} placeholder="Enter url slug" />
              </Form.Item>
              <Form.Item
                name="countryId"
                label="Country"
                valuePropName="defaultValue"
                initialValue={row?.countryId}
                messageVariables={{ name: 'Country' }}
                rules={[{ required: true }]}
              >
                <CustomSelect
                  disabled={Boolean(id)}
                  placeholder={'Select country'}
                  defaultQueryParams={{ page: 1, perPage: 22 }}
                  selectId={'selectCountries'}
                  onClear={() => resetCountryAndCitySelects()}
                  onUpdateSelect={fetchCountriesForSelect}
                  onChange={(value) => {
                    if (value && value.urlSlug) {
                      clearFormData('selectCities')
                      form.resetFields(['cityId']);
                      fetchCitiesForSelect('selectCities',
                        {
                          countryUrlSlug: value.urlSlug
                        },
                        {
                          clearDataBeforeFetch: true,
                          saveParams: true
                        });
                    }
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="cityId"
                label="City"
                valuePropName="defaultValue"
                initialValue={row?.cityId}
                messageVariables={{ name: 'City' }}
                rules={[{ required: true }]}
              >
                <CustomSelect
                  disabled={!selectCity?.opened || Boolean(id)}
                  placeholder={'Select city'}
                  defaultQueryParams={{ page: 1, perPage: 50 }}
                  selectId={'selectCities'}
                  onUpdateSelect={fetchCitiesForSelect}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              {/*<Form.Item*/}
              {/*  label="Meta Title"*/}
              {/*  name="metaTitle"*/}
              {/*  initialValue={row && row?.metaTitle ? row.metaTitle : ''}*/}
              {/*>*/}
              {/*  <Input placeholder="Enter meta title" />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item*/}
              {/*  label="Meta Description"*/}
              {/*  name="metaDescription"*/}
              {/*  initialValue={row && row?.metaDescription ? row.metaDescription : ''}*/}
              {/*>*/}
              {/*  <Input placeholder="Enter meta description" />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item*/}
              {/*  label="Departments Meta Title"*/}
              {/*  name="departmentsMetaTitle"*/}
              {/*  initialValue={row && row?.departmentsMetaDescription ? row.departmentsMetaDescription : ''}*/}
              {/*>*/}
              {/*  <Input placeholder="Enter departments meta title" />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item*/}
              {/*  label="Departments Meta Description"*/}
              {/*  name="departmentsMetaDescription"*/}
              {/*  initialValue={row && row?.departmentsMetaDescription ? row.departmentsMetaDescription : ''}*/}
              {/*>*/}
              {/*  <Input placeholder="Enter departments meta description" />*/}
              {/*</Form.Item>*/}
              <Form.Item
                name="featuresAndFacts"
                label="Features & Facts"
                initialValue={row ? row.featuresAndFacts : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                initialValue={row ? row.description : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="faq"
                label="FAQ"
                initialValue={row ? row.faq : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Vendor" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="vendorId"
                  label="Vendor"
                  rules={[{ required: true, }]}
                  initialValue={row && row?.vendorId ? row.vendorId : undefined}
                  valuePropName="defaultValue"
                  messageVariables={{ name: 'Vendor' }}
                >
                  <CustomSelect
                    placeholder={'Select vendor'}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    selectId={'hospitalSelectVendor'}
                    transformFieldLabel={(i) => ({
                      ...i,
                      name: `${i.firstName} ${i.lastName}${i.company ? `, ${i.company.name}` : ''}`
                    })}
                    onClear={() => clearFormData('hospitalSelectVendor')}
                    onUpdateSelect={fetchVendor}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Card>
              <Card
                bordered={false} style={{ marginTop: 24 }}
                className={styles.customSelect}
              >
                <Form.Item
                  name="tagsIds"
                  label="Select Tags"
                  valuePropName="defaultValue"
                  initialValue={row && row.tags ? row.tags.map(item => item.id) : []}
                  messageVariables={{ name: 'Tags' }}
                  rules={[{ required: true }]}
                >
                  <CustomSelect
                    disabled={languageId > 1}
                    mode="multiple"
                    placeholder={'Select tags'}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    selectId="selectTags"
                    onUpdateSelect={fetchTagsForSelect}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="certificatesIds"
                  label="Select Certificates"
                  valuePropName="defaultValue"
                  initialValue={row && row.certificates ? row.certificates.map(item => item.id) : []}
                >
                  <CustomSelect
                    disabled={languageId > 1}
                    mode={'multiple'}
                    optionLabelProp={'name'}
                    placeholder={'Select certificates'}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    selectId={'selectCertificates'}
                    onUpdateSelect={fetchCertificatesForSelect}
                    style={{ width: '100%' }}
                    fieldNames={{
                      label: 'label',
                      value: 'id',
                    }}
                    transformFieldLabel={(i: any) => {
                      return {
                        id: i.id,
                        name: i.name,
                        label: (
                          <div className={styles.itemWrapper}>
                            <Image
                              style={{ width: 50 }}
                              preview={false}
                              src={i.imgPath}
                            />
                            <div>{i.name}</div>
                          </div>
                        ),
                      }
                    }}
                  />
                </Form.Item>
              </Card>
              <Card
                title="External resource"
                bordered={false} style={{ marginTop: 24 }}
              >
                <Form.Item
                  name="site"
                  label="Website Address"
                  messageVariables={{ name: 'Website url' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.site ?? ''}
                >
                  <Input disabled={languageId > 1} placeholder="Enter the URL" />
                </Form.Item>
                <Form.Item
                  name="googleReviewUrl"
                  label="Google Review Url"
                  messageVariables={{ name: 'Google Review Url' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.googleReviewUrl ?? ''}
                >
                  <Input disabled={languageId > 1} placeholder="Enter the URL" />
                </Form.Item>
                <Form.Item
                  name="location"
                  label="Latitude and Longitude"
                  extra={`Enter Latitude and Longitude by comma. Example: 50.4989, 60.6214`}
                  messageVariables={{ name: 'Latitude and Longitude' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.location ?? ''}
                >
                  <Input disabled={languageId > 1} placeholder="Enter the URL" />
                </Form.Item>
              </Card>
              <Card
                title="Statistics"
                bordered={false} style={{ marginTop: 24 }}
              >
                <Form.Item
                  name="foundedIn"
                  label="Founded In"
                  messageVariables={{ name: 'Founded In' }}
                  rules={[{ type: 'number', min: 0 }]}
                  initialValue={row?.foundedIn ?? ''}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={languageId > 1}
                    placeholder="Enter the date of establishment"
                  />
                </Form.Item>
                <Form.Item
                  name="patientsCount"
                  label="Number of Patients"
                  messageVariables={{ name: 'Number of Patients' }}
                  rules={[{ type: 'number', min: 0 }]}
                  initialValue={row?.patientsCount ?? ''}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={languageId > 1}
                    placeholder="Enter the number of patients"
                  />
                </Form.Item>
                <Form.Item
                  name="bedsCount"
                  label="Number of Beds"
                  messageVariables={{ name: 'Number of Beds' }}
                  rules={[{ type: 'number', min: 0 }]}
                  initialValue={row?.bedsCount ?? ''}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={languageId > 1}
                    placeholder="Enter the number of beds"
                  />
                </Form.Item>
              </Card>
              <Card
                title="Score"
                bordered={false} style={{ marginTop: 24 }}
              >
                <Form.Item
                  name="score"
                  label="AiroScore"
                  messageVariables={{ name: 'AiroScore' }}
                  rules={[{ type: 'number', min: 0, max: 10 }]}
                  initialValue={row?.score ? row.score : 1}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={languageId > 1}
                    placeholder="Enter the AiroScore"
                    step="0.1"
                  />
                </Form.Item>
                <Form.Item
                  name="patientScore"
                  label="Patient Score"
                  messageVariables={{ name: 'Patient Score' }}
                  rules={[{ type: 'number', min: 0, max: 100 }]}
                  initialValue={row?.patientScore ? row.patientScore : 1}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={languageId > 1}
                    placeholder="Enter the number of patients"
                  />
                </Form.Item>
              </Card>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isLanguageActive"
                  valuePropName="checked"
                  initialValue={languageId === 1 ? true : row?.isLanguageActive}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId === 1}>Active Language</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isBestPrice"
                  valuePropName="checked"
                  initialValue={row ? row?.isBestPrice : false}
                  style={{ margin: 0, marginTop: 12 }}
                >
                  <Checkbox disabled={languageId > 1}>Best price</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isFastAppointment"
                  valuePropName="checked"
                  initialValue={row ? row?.isFastAppointment : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Fast appointment</Checkbox>
                </Form.Item>
                {/*<Form.Item*/}
                {/*  name="isInsurance"*/}
                {/*  valuePropName="checked"*/}
                {/*  initialValue={row ? row?.isInsurance : false}*/}
                {/*  style={{ margin: 0, marginTop: 12 }}*/}
                {/*>*/}
                {/*  <Checkbox disabled={languageId > 1}>Show FAQ</Checkbox>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*  name="isUseCityDescription"*/}
                {/*  valuePropName="checked"*/}
                {/*  initialValue={row ? row?.isUseCityDescription : false}*/}
                {/*  style={{ margin: 0, marginTop: 12 }}*/}
                {/*>*/}
                {/*  <Checkbox disabled={languageId > 1}>Use city description if exist</Checkbox>*/}
                {/*</Form.Item>*/}
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          // type="ghost"
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}

