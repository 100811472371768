import * as React from 'react';
import { useEffect, useRef } from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Rule } from 'antd/lib/form';

import { Language } from '@modelTypes/language';
import UploadPhotoModal from '@components/UploadPhotoModal';
import { setFormErrors } from '@api/helpers';
import styles from './MainDataStep.module.scss';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Row,
  Space,
  Spin,
  Tooltip
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { CreateDoctorRequest } from '@api/types';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { prepareFormDataForBackend } from '@helpers/antForm';
import QuillEditor from '@components/QuillEditor';
import SelectStep from '@containers/Doctors/components/SelectStep';
import { useDoctorStore } from '@zustandStorage/doctor/doctor';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { Doctor } from '@modelTypes/doctor';

export default function MainDataStep() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [messageApi, contextHolder] = message.useMessage();
  const { parseForm, parseData, generateAboutForm, generateAboutDoctorInfo } = useDoctorStore();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [imgPreview, setImgPreview] = React.useState<string>('');
  const { clearFormData, fetchDoctor, createDoctor, updateDoctor, form: { row, loading } } = useDoctorStore()
  const fieldAboutRef = useRef<any>();
  const {
    fetchLanguages,
    fetchSpecializationForSelect,
    fetchVendor,
    setData,
    clearFormData: clearSelectData
  } = useSelect();
  const [form] = Form.useForm<CreateDoctorRequest>();
  const showOldNameField = Boolean(!loading && !(row?.firstName && row?.secondName) && id);

  const onSelectLanguage = async (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      fetchDoctor(parseInt(id, 10), { languageId: language.id })
        .then((r: Doctor) => {
          resetForm();
          setSelectsInitialDate(r);
        });
    }
  };

  const onPhotoSave = async (cropper: Cropper, type: string) => {
    cropper.getCroppedCanvas().toBlob(
      (blob: Blob | null) => {
        if (!blob) {
          return;
        }
        
        // await formik.setFieldValue('photoToSave', blob);
        form.setFields([
          {
            name: 'photoToSave',
            value: blob,
          }
        ]);

        setImgPreview(URL.createObjectURL(blob));

        if (id) {
          form.submit();
          // formik.handleSubmit();
        }
      },
      type,
      type === 'image/jpeg' || type === 'image/jpg' ? .95 : undefined
    );
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleParseData = async () => {
    parseData(+id)
      .then((r) => {
        if (r) {
          if (r.status === 'success') {
            message.success('The publication parsing process has been started. This can take up to several minutes.');
          }

          if (r.status === 'processAlreadyRunning') {
            message.error(`The parsing process is already running for #${r.doctorId}. Please wait until it is finished.`);
          }
        } else {
          message.error('An error occurred, please try again now');
        }
      });
  };

  const handleGenerateAboutDoctorInfo = async () => {
    generateAboutDoctorInfo(+id)
      .then((r) => {
        if (r.length && fieldAboutRef && fieldAboutRef.current && fieldAboutRef.current.quillRef) {
          fieldAboutRef.current.quillRef.getEditor().setText(r);
        } else {
          message.error('An error occurred, please try again now');
        }
      });
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving doctor...',
      duration: 0,
    });

    setFormSubmitting(true);
    const formData = prepareFormDataForBackend(
      form.getFieldsValue(true),
      {
        selectFieldNames: ['specializationsIds', 'languagesIds', 'vendorId'],
      }
    );
    formData.append('languageId', languageId.toString());

    if (id) {
      await updateDoctor(parseInt(id, 10), formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The doctor has been successfully updated');
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createDoctor(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The doctor has been successfully created');
          history.push(`/doctors/edit/${data.id}`);
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  const setSelectsInitialDate = (r: Doctor) => {
    setData('doctorSelectSpecializations', r && r.specializations ? r.specializations : []);
    setData('doctorSelectLanguages', r && r.languages ? r.languages : []);
    setData('doctorSelectVendor', r && r.vendorId ? [r.vendor] : []);
  };

  const validateURL = (name: string): Rule => ({
    validator(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }

      let domainRegex: RegExp;
      let domainName: string;

      switch (name) {
        case 'pubmedUrl':
          domainRegex = /^https:\/\/pubmed\.ncbi\.nlm\.nih\.gov/;
          domainName = 'PubMed';
          break;
        case 'reserchgateUrl':
          domainRegex = /^https:\/\/(www\.)?researchgate\.net/;
          domainName = 'ResearchGate';
          break;
        case 'semanticscholarUrl':
          domainRegex = /^https:\/\/(www\.)?semanticscholar\.org/;
          domainName = 'Semantic Scholar';
          break;
        default:
          callback();
          return;
      }

      if (!domainRegex.test(value)) {
        callback(`Please enter a valid URL for ${domainName}`);
      } else {
        callback();
      }
    },
  });

  useEffect(() => {
    if (row && row.imgPath) {
      setImgPreview(row.imgPath);
    }
  }, [row]);

  useEffect(() => {
    resetForm();
  }, [languageId, id, row]);

  useEffect(() => {
    return () => {
      clearFormData();
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.doctorsAntGrid || {};
          history.push(`/doctors?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} Doctor`}
        // subTitle={<SelectedPlaceValue data={place} />}
        extra={[
          <SelectStep key="selectStep" disabled={!row} />,
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          id={'test'}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              {showOldNameField && (
                <Form.Item
                  name="name"
                  label="Name"
                  messageVariables={{ name: 'Name' }}
                  rules={[{ type: 'string', max: 255 }]}
                  initialValue={row?.name}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
              )}
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    messageVariables={{ name: 'First Name' }}
                    rules={[{ required: !showOldNameField, type: 'string', max: 64 }]}
                    initialValue={row?.firstName}
                  >
                    <Input placeholder="Enter first Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="secondName"
                    label="Second Name"
                    messageVariables={{ name: 'Name' }}
                    rules={[{ required: !showOldNameField, type: 'string', max: 64 }]}
                    initialValue={row?.secondName}
                  >
                    <Input placeholder="Enter second Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="title"
                    label="Title"
                    messageVariables={{ name: 'Title' }}
                    rules={[{ type: 'string', max: 64 }]}
                    initialValue={row?.title}
                  >
                    <Input placeholder="Enter title" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="postNominals"
                    label="Post Nominals"
                    messageVariables={{ name: 'Post Nominals' }}
                    rules={[{ type: 'string', max: 64 }]}
                    initialValue={row?.postNominals}
                  >
                    <Input placeholder="Enter post nominals" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="specialization"
                label="Specialization"
                messageVariables={{ name: 'Specialization' }}
                rules={[{ type: 'string' }]}
                initialValue={row ? row?.specialization : undefined}
              >
                <Input placeholder="Enter specialization" />
              </Form.Item>
              <Form.Item
                name="specializationsIds"
                label="Specializations"
                initialValue={row && row.specializations ? row.specializations.map((i) => i.id) : []}
                valuePropName="defaultValue"
              >
                <CustomSelect
                  mode={'multiple'}
                  placeholder={'Select specializations'}
                  defaultQueryParams={{ page: 1, perPage: 50 }}
                  selectId={'doctorSelectSpecializations'}
                  onUpdateSelect={fetchSpecializationForSelect}
                  style={{ width: '100%' }}
                  onClear={() => clearSelectData('doctorSelectSpecializations')}
                />
              </Form.Item>
              <Form.Item
                name="languagesIds"
                label="Languages"
                initialValue={row && row.languages ? row.languages.map((i) => i.id) : []}
                valuePropName="defaultValue"
              >
                <CustomSelect
                  mode={'multiple'}
                  optionLabelProp={'name'}
                  placeholder={'Select languages'}
                  defaultQueryParams={{ page: 1, perPage: 50 }}
                  selectId={'doctorSelectLanguages'}
                  onUpdateSelect={fetchLanguages}
                  style={{ width: '100%' }}
                  onClear={() => clearSelectData('doctorSelectLanguages')}
                  fieldNames={{
                    label: 'label',
                    value: 'id',
                  }}
                  transformFieldLabel={(i: any) => {
                    return {
                      id: i.id,
                      name: i.name,
                      label: (
                        <Space size={'middle'}>
                          <Image
                            preview={false}
                            width={24}
                            height={16}
                            src={`${process.env.REACT_APP_BUCKET_URL}/icons/country-flags/${i.countryCode}.svg`}
                            alt={i.name + ' flag'}
                          />
                          <div>{i.name}</div>
                        </Space>
                      ),
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Started Working in"
                name="startedWorkingIn"
                initialValue={row && row?.startedWorkingIn ? row.startedWorkingIn : ''}
                // initialValue={row && row.startedWorkingIn ? moment().year(row.startedWorkingIn) : undefined}
              >
                <Input disabled={languageId > 1} placeholder="Enter year" type="number" style={{ width: 200 }} />
                {/*<DatePicker*/}
                {/*  disabledDate={d => !d || d.isAfter(Date.now())}*/}
                {/*  picker="year"*/}
                {/*  style={{ width: 200 }}*/}
                {/*/>*/}
              </Form.Item>
              <Space style={{ width: '100%', justifyContent: 'space-between', marginBottom: 8 }}>
                <span>Summary</span>
                <Button
                  type="primary"
                  loading={generateAboutForm.loading}
                  onClick={handleGenerateAboutDoctorInfo}
                >
                  Generate
                </Button>
              </Space>
              <Form.Item
                name="about"
                initialValue={row ? row.about : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor
                  ref={fieldAboutRef}
                />
              </Form.Item>
              <Form.Item
                name="education"
                label="Education"
                initialValue={row ? row.education : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="experience"
                label="Experience"
                initialValue={row ? row.experience : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="awards"
                label="Awards, memberships, publications"
                initialValue={row ? row.awards : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="faq"
                label="Faq"
                initialValue={row ? row.faq : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Photo" bordered={false}>
                <div className={styles.photoPreview}>
                  <div>
                    <Form.Item
                      name="photoToSave"
                      style={{ display: 'none' }}
                      initialValue=""
                    >
                      <Input style={{ display: 'none' }} />
                    </Form.Item>
                    <Avatar
                      // sx={{
                      //   height: 120,
                      //   width: 120,
                      //   // ml: 1
                      // }}
                      className={styles.avatar}
                      src={imgPreview}
                    />
                  </div>
                  <div className={styles.actions}>
                    {!!imgPreview.length && (
                      <Tooltip title="Delete item">
                        <Button
                          danger
                          type="primary"
                          // shape="round"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            form.setFieldValue('imgPath', false);
                            setImgPreview('');
                          }}
                          style={{ marginRight: 8 }}
                          disabled={languageId > 1}
                        />
                      </Tooltip>
                    )}
                    <UploadPhotoModal
                      handleSaveImage={onPhotoSave}
                      uploadButtonProps={
                        {
                          style: { width: 32, height: 32 },
                          type: 'primary',
                          disabled: languageId > 1
                        }
                      }
                      cropperProps={
                        {
                          initialAspectRatio: 1,
                          viewMode: 0,
                          minCropBoxHeight: 10,
                          minCropBoxWidth: 10,
                          // background: false,
                          responsive: true,
                          aspectRatio: 1,
                          guides: true,
                        }
                      }
                      modalClassName={styles.cropperContainer}
                    />
                  </div>
                </div>
              </Card>
              <Card title="Vendor" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="vendorId"
                  label="Vendor"
                  rules={[{ required: true, }]}
                  initialValue={row && row?.vendorId ? row.vendorId : undefined}
                  valuePropName="defaultValue"
                  messageVariables={{ name: 'Vendor' }}
                >
                  <CustomSelect
                    placeholder={'Select vendor'}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    selectId={'doctorSelectVendor'}
                    transformFieldLabel={(i) => ({
                      ...i,
                      name: `${i.firstName} ${i.lastName}${i.company ? `, ${i.company.name}` : ''}`
                    })}
                    onClear={() => clearSelectData('doctorSelectVendor')}
                    onUpdateSelect={fetchVendor}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Card>
              <Card
                title="Data scraping resource"
                bordered={false} style={{ marginTop: 24 }}
                // extra={
                //   <Button
                //     type="primary"
                //     loading={parseForm.loading}
                //     onClick={handleParseData}
                //   >
                //     Parse
                //   </Button>
                // }
              >
                <Form.Item
                  name="pubmedUrl"
                  label="PubMed url"
                  messageVariables={{ name: 'PubMed url' }}
                  rules={[
                    { type: 'string' },
                    validateURL('pubmedUrl')
                  ]}
                  initialValue={row?.pubmedUrl ?? ''}
                >
                  <Input disabled={languageId > 1} placeholder="Enter the URL of the publications page" />
                </Form.Item>
                <Form.Item
                  name="reserchgateUrl"
                  label="ResearchGate url"
                  messageVariables={{ name: 'ResearchGate url' }}
                  rules={[
                    { type: 'string' },
                    validateURL('reserchgateUrl')
                  ]}
                  initialValue={row?.reserchgateUrl ?? ''}
                >
                  <Input disabled={languageId > 1} placeholder="Enter the URL of the publications page" />
                </Form.Item>
                <Form.Item
                  name="semanticscholarUrl"
                  label="Semantic Scholar url"
                  messageVariables={{ name: 'Semantic Scholar url' }}
                  rules={[
                    { type: 'string' },
                    validateURL('semanticscholarUrl')
                  ]}
                  initialValue={row?.semanticscholarUrl ?? ''}
                >
                  <Input disabled={languageId > 1} placeholder="Enter the URL of the publications page" />
                </Form.Item>
              </Card>
              <Card
                title="External resource"
                bordered={false} style={{ marginTop: 24 }}
              >
                <Form.Item
                  name="googleReviewUrl"
                  label="Google URL"
                  messageVariables={{ name: 'Google reviews url' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.googleReviewUrl ?? ''}
                >
                  <Input disabled={languageId > 1} placeholder="Enter url for google reviews" />
                </Form.Item>
                <Form.Item
                  name="websiteUrl"
                  label="Website Address"
                  messageVariables={{ name: 'Website url' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.websiteUrl ?? ''}
                >
                  <Input disabled={true} placeholder="Enter the URL" />
                </Form.Item>
                <Form.Item
                  name="linkedinUrl"
                  label="LinkedIn"
                  messageVariables={{ name: 'Google Review Url' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.linkedinUrl ?? ''}
                >
                  <Input disabled={true} placeholder="Enter the linkedin Url" />
                </Form.Item>
                <Form.Item
                  name="facebookUrl"
                  label="Facebook"
                  messageVariables={{ name: 'Facebook' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.facebookUrl ?? ''}
                >
                  <Input disabled={true} placeholder="Enter the facebook Url" />
                </Form.Item>
                <Form.Item
                  name="youtubeUrl"
                  label="YouTube"
                  messageVariables={{ name: 'YouTube' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.youtubeUrl ?? ''}
                >
                  <Input disabled={true} placeholder="Enter the youtube Url" />
                </Form.Item>
                <Form.Item
                  name="xUrl"
                  label="X"
                  messageVariables={{ name: 'X' }}
                  rules={[{ type: 'string' }]}
                  initialValue={row?.xUrl ?? ''}
                >
                  <Input disabled={true} placeholder="Enter the X Url" />
                </Form.Item>
                {/*<Form.Item*/}
                {/*  name="location"*/}
                {/*  label="Latitude and Longitude"*/}
                {/*  extra={`Enter Latitude and Longitude by comma. Example: 50.4989, 60.6214`}*/}
                {/*  messageVariables={{ name: 'Latitude and Longitude' }}*/}
                {/*  rules={[{ type: 'string' }]}*/}
                {/*  initialValue={row?.location ?? ''}*/}
                {/*>*/}
                {/*  <Input disabled={languageId > 1} placeholder="Enter the URL" />*/}
                {/*</Form.Item>*/}
              </Card>
              <Card
                title="Score"
                bordered={false} style={{ marginTop: 24 }}
              >
                <Form.Item
                  name="customScore"
                  label="AiroScore"
                  messageVariables={{ name: 'AiroScore' }}
                  rules={[{ type: 'number', min: 0, max: 10 }]}
                  initialValue={row?.customScore ? row.customScore : 1}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={languageId > 1}
                    placeholder="Enter the AiroScore"
                    step="0.1"
                  />
                </Form.Item>
                <Form.Item
                  name="customPatientScore"
                  label="Patient Score"
                  messageVariables={{ name: 'Patient Score' }}
                  rules={[{ type: 'number', min: 0, max: 100 }]}
                  initialValue={row?.customPatientScore ? row.customPatientScore : 1}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={languageId > 1}
                    placeholder="Enter the number of patients"
                  />
                </Form.Item>
              </Card>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isLanguageActive"
                  valuePropName="checked"
                  initialValue={languageId === 1 ? true : row?.isLanguageActive}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId === 1}>Active Language</Checkbox>
                </Form.Item>
                <Form.Item
                  name="hasOnlineConsultation"
                  valuePropName="checked"
                  initialValue={row ? row?.hasOnlineConsultation : false}
                  style={{ margin: 0, marginTop: 12 }}
                >
                  <Checkbox disabled={languageId > 1}>Online Consultation</Checkbox>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
