import * as React from 'react';
import { useEffect } from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import map from 'lodash/map';
import Editor from '@components/Editor';
import { Language } from '@modelTypes/language';
import UploadPhotoModal from '@components/Modal/UploadPhoto';
import { setFormErrors } from '@api/helpers';
import styles from './Create.module.scss';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';

import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import urlSlug from 'url-slug';
import { CreateArticleRequest } from '@api/types';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { prepareFormDataForBackend } from '@helpers/antForm';
import { BlogTypes } from '@modelTypes/blog';
import { useBlogStore } from '@zustandStorage/blogs/blogs';
import useLoadAuthors from '@base/Select/hooks/useLoadAuthors';
import useLoadDoctors from '@base/Select/hooks/useLoadDoctors';
import SelectAuthor from '@base/Select/SelectAuthor';
import SelectDoctor from '@base/Select/SelectDoctor';
import useLoadHospitals from '@base/Select/hooks/useLoadHospitals';
import SelectHospital from '@base/Select/SelectHospital';
import useLoadSearchSection from '@base/Select/hooks/useLoadSearchSectionRelations';
import SelectSearchSection from '@base/Select/SelectSearchSection';

const { Text } = Typography;
const { TextArea } = Input;


const titles: Record<BlogTypes, string> = {
  'articles': 'Article',
  'disease-guides': 'Disease Guides',
  'treatment-guides': 'Treatment Guides',
};

interface RouterParams {
  type: BlogTypes;
  id: string;
}

export default function BlogCreate() {
  const history = useHistory();
  const { id, type } = useParams<RouterParams>();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [imgPreview, setImgPreview] = React.useState<string>('');
  const [uploadModalOpen, setUploadModalOpen] = React.useState<boolean>(false);
  const [form] = Form.useForm<CreateArticleRequest>();
  const { clearFormData, fetchBlog, createBlog, updateBlog, form: { row, loading } } = useBlogStore();

  const authorsS = useLoadAuthors();
  const doctorsS = useLoadDoctors();
  const hospitalsS = useLoadHospitals();
  const searchSectionsS = useLoadSearchSection();

  const onSelectLanguage = async (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      await fetchBlog(parseInt(id, 10), { languageId: language.id })
        .then(() => {
          resetForm();
        });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const onPhotoSave = async (canvas: any) => {
    canvas.toBlob(
      async (blob: Blob) => {
        form.setFields([
          {
            name: 'photoToSave',
            value: blob,
          }
        ]);

        setImgPreview(URL.createObjectURL(blob));

        if (id) {
          form.submit();
        }
      },
      'image/jpeg',
      1
    );
  };

  const handleOnBlur = () => {
    if (isEmpty(form.getFieldValue('urlSlug')) && !isEmpty(form.getFieldValue('title'))) {
      const value = urlSlug(form.getFieldValue('title'));
      form.setFieldValue('urlSlug', value);
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving blog...',
      duration: 0,
    });

    setFormSubmitting(true);
    const formData = prepareFormDataForBackend(
      form.getFieldsValue(true),
      { selectFieldNames: ['authorsIds', 'reviewersIds', 'doctorsIds', 'hospitalsIds', 'searchSectionsIds'] }
    );

    formData.append('languageId', languageId.toString());
    formData.set('publishedAt', dayjs(form.getFieldValue('publishedAt')).format('YYYY-MM-DD'));
    formData.append('type', type);
    if (id) {
      await updateBlog(parseInt(id, 10), formData as any)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The blog has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createBlog(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The blog has been successfully created');
          history.push(`/blog/${type}/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  useEffect(() => {
    if (row && row.imgPath) {
      setImgPreview(row.imgPath);
    }
  }, [row]);

  useEffect(() => {
    resetForm();
  }, [languageId, id, row]);

  useEffect(() => {
    return () => {
      clearFormData();
    }
  }, [])


  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.authorsAntGrid || {};
          history.push(`/blog/${type}?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} ${titles[type]}`}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="title"
                label="Title"
                messageVariables={{ name: 'Title' }}
                rules={[{ required: true, type: 'string', max: 255 }]}
                initialValue={row?.title}
              >
                <Input onBlur={handleOnBlur} placeholder="Enter title" />
              </Form.Item>
              <Form.Item
                name="urlSlug"
                label="Url Slug"
                messageVariables={{ name: 'Url Slug' }}
                rules={[{ required: true, type: 'string', max: 128 }]}
                initialValue={row?.urlSlug}
              >
                <Input disabled={languageId > 1} placeholder="Enter url slug" />
              </Form.Item>
              <Form.Item
                name="metaTitle"
                label="Meta Title"
                messageVariables={{ name: 'Meta Title has been string' }}
                rules={[{ type: 'string' }]}
                initialValue={row ? row?.metaTitle : ''}
              >
                <Input placeholder="Enter Meta Title" />
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="metaDescription"
                initialValue={row ? row?.metaDescription : ''}
              >
                {/*<Input placeholder="Enter Meta Description" />*/}
                <TextArea placeholder="Enter Meta Description" autoSize />

              </Form.Item>
              <Form.Item
                name="authorsIds"
                label="Select Author"
                initialValue={row && row?.authors !== null ? map(row.authors, (i) => i.id) : undefined}
                valuePropName="defaultValue"
              >
                <SelectAuthor
                  disabled={languageId > 1}
                  options={authorsS.data}
                  onChange={() => null}
                  style={{ width: '100%' }}
                  mode="multiple"
                />
              </Form.Item>
              <Form.Item
                name="reviewersIds"
                label="Select Reviewers"
                initialValue={row && row?.reviewers !== null ? map(row.reviewers, (i) => i.id) : undefined}
                valuePropName="defaultValue"
              >
                <SelectAuthor
                  disabled={languageId > 1}
                  options={authorsS.data}
                  onChange={() => null}
                  style={{ width: '100%' }}
                  mode="multiple"
                />
              </Form.Item>
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Form.Item
                  name="doctorsIds"
                  label="Select Doctors"
                  initialValue={row && row?.doctors !== null ? map(row.doctors, (i) => i.id) : undefined}
                  valuePropName="defaultValue"
                  help={'Insert "{{ slider__doctors }}" to content'}
                >
                  <SelectDoctor
                    disabled={languageId > 1}
                    options={doctorsS.data}
                    onChange={() => null}
                    style={{ width: '100%' }}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  name="hospitalsIds"
                  label="Select Hospitals"
                  initialValue={row && row?.hospitals !== null ? map(row.hospitals, (i) => i.id) : undefined}
                  valuePropName="defaultValue"
                  help={'Insert "{{ slider__hospitals }}" to content'}
                >
                  <SelectHospital
                    disabled={languageId > 1}
                    options={hospitalsS.data}
                    onChange={() => null}
                    style={{ width: '100%' }}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  name="searchSectionsIds"
                  label="Select Search Section"
                  initialValue={row && row?.searchSections !== null ? map(row.searchSections, (i) => i.id) : undefined}
                  valuePropName="defaultValue"
                  help={'Insert "{{ slider__search_sections_countries }}" or "{{ slider__search_sections_cities }}"" to content'}
                >
                  <SelectSearchSection
                    disabled={languageId > 1}
                    options={searchSectionsS.data}
                    onChange={() => null}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Text
                  strong
                >
                  {'<facts>Enter your facts here</facts>'} - Facts
                </Text>
                <Form.Item
                  name="content"
                  label="Content"
                  initialValue={row ? row?.content : ''}
                  valuePropName="defaultValue"
                >
                  <Editor
                    entityName="content"
                    sx={{ mb: 2 }}
                  />
                </Form.Item>
              </Space>


            </Col>
            <Col span={8}>
              <Card title="Photo" bordered={false} style={{ marginTop: 24 }}>
                <div className={styles.photoPreview}>
                  <div>
                    <Form.Item
                      name="photoToSave"
                      style={{ display: 'none' }}
                      initialValue=""
                    >
                      <Input style={{ display: 'none' }} />
                    </Form.Item>
                    <Avatar
                      shape="square"
                      className={styles.avatar}
                      src={imgPreview}
                    />
                  </div>
                  <div>
                    {!!imgPreview.length && (
                      <Tooltip title="Delete item">
                        <Button
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            form.setFieldValue('imgPath', false);
                            setImgPreview('');
                          }}
                          style={{ marginRight: 8 }}
                          disabled={languageId > 1}
                        />
                      </Tooltip>
                    )}
                    <Button
                      type="primary"
                      onClick={() => setUploadModalOpen(true)}
                      disabled={languageId > 1}
                    >
                      {imgPreview.length ? 'Edit' : 'Add'}
                    </Button>
                  </div>
                </div>
                <Form.Item
                  name="publishedAt"
                  label="Date of Publish"
                  rules={[{
                    type: 'object' as const,
                    message: 'Please select time!'
                  }]}
                  initialValue={dayjs(row ? row.publishedAt : dayjs(), 'YYYY-MM-DD')}
                >
                  <DatePicker
                    disabled={languageId > 1}
                    format={'DD-MM-YYYY'} />
                </Form.Item>
              </Card>

              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row?.isActive}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isLanguageActive"
                  valuePropName="checked"
                  initialValue={languageId === 1 ? true : row?.isLanguageActive}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId === 1}>Active Language</Checkbox>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
      <UploadPhotoModal
        minWidth={160}
        minHeight={94}
        aspect={560/330}
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        onSave={onPhotoSave}
      />
    </>
  );
}
