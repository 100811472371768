import { useEffect } from 'react';
import { useDoctorSelectStore } from '@zustandStorage/blogs/doctorsForSelect';

export default function useLoadDoctors() {
  const { data, loading } = useDoctorSelectStore((state) => state.select);
  const { fetchDoctorForSelect } = useDoctorSelectStore();

  useEffect(() => {
    if (!data.length && !loading) {
      fetchDoctorForSelect({ page: 1, perPage: 5000 });
    }
  }, []);

  return {data, loading};
}